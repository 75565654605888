@import '../../styles/colorMapping.scss';
.ant-layout-footer {
  &.login-footer {
    background-color: color(footerGrey);
    z-index: 10;

    .copyright {
      color: color(copyRightTextBlack);
      font-weight: 500;
      text-align: center;
    }
  }
}
