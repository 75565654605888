@import '~antd/dist/antd.css';
@import '../src/styles/colorMapping.scss';
.logged-in-layout {
  min-height: 100vh;
}

h1 {
  font-size: 1.5em;
  padding: 8px;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
