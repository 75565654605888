
@import './../styles/colorMapping.scss';
.go-to-main-page{
  position: relative;
  z-index: 999;
  right: 30px;
  top: 30px;  
  float: right;
  .secondary-button{
    font-size: 14px;
    min-height: 30px;
  }
}
.pageNotFound {
  min-height: 80%;
  width: 80%;
  height: auto;
  position: fixed;
  top: 4%;
  left: 10%;
  border: 1px solid color(grey);
 img{
  width: 100%;
  height: 100%;
} 
}