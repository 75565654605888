$colors: (
primary:#1890ff,
secondary:#91c4e8,
lightBlue:#e6f7ff,
white:#FFF,
grey:#b0bec5,
darkGrey:#3c3c3c,
black:#000,
copyRightTextBlack:#333,
footerGrey:#f0f2f5,
errorRed:#D90707
);