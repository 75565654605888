@import '../../styles/colorMapping.scss';
.site-layout-background {
  &.ant-layout-header {
    background-color: color(primary);
    height: 60px;
  }

  .ant-dropdown-trigger {
    &.ant-dropdown-link {
      color: color(white);
      float: right;
      font-weight: 500;
      margin-right: 20px;
    }
    &.ant-dropdown-link:hover {
      color: color(white);
    }
  }
}
.ant-dropdown {
  top: 45px !important;
  position: fixed !important;
}
.ant-dropdown-menu{
  width: 150px;
  float: right;
}

.dropdown-icons {
  font-size: 20px;
  margin: 0 9px 0 0px;
  color: color(darkGrey);
}
.dropdown-list-items {
  color: color(darkGrey);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}
.header-menu-item {
  margin: 0.125em 0;
  padding: 10px 25px !important;
}
.header-menu-item:hover {
  background: color(lightBlue) !important;
  border-color: transparent !important;
  color: color(white) !important;
  cursor: pointer;
}

.ant-dropdown-menu-item:hover {
  .dropdown-list-items {
    color: color(primary) !important;
    cursor: pointer;
    font-weight: 500;
  }
  .dropdown-icons {
    color: color(primary) !important;
    cursor: pointer;
  }
}
.ant-dropdown-menu-item {
  height: 40px !important;
}
