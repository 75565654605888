@import '../../styles/colorMapping.scss';
.ant-layout-sider {
  &.ant-layout-sider-dark {
    min-height: 100vh;
    background-color: color(white);

  }
}
.site-layout-background {
  background: color(white);
}
.sidebar-logo {
  height: 60px;
  line-height: 60px;
  text-align: center;

  .img {
    height: 44px;
  }
}
.sidebar-icons {
  color: color(grey);
  font-size: 20px !important;
  margin-right: 8px;
}
