@import '../../styles/colorMapping.scss';
.ant-message {
  .ant-message-custom-content.ant-message-error {
    padding: 16px 16px;
    color: color(grey);
    display: flex;
    font: 600 14px Montserrat;
    width: fit-content;
  }
}
.ant-message {
  .ant-message-custom-content.ant-message-success {
    padding: 16px 16px;
    color: color(grey);
    display: flex;
    font: 600 14px Montserrat;
    width: fit-content;
  }
}
.ant-message-notice-content {
  padding: 0px;
  margin-left: auto;
}
.custom-message-area {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  padding-left: 0px;
  width: 23vw;
}

.toast-area {
  height: max-content;
  margin-left: auto !important;
  display: flex;
}
.message-header {
  text-align: left;
}
.message-icon {
  color: color(white);
}
.message-label {
  font-weight: 700;
}
